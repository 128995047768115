import React from 'react'
import './contact.css'
import{MdAttachEmail} from 'react-icons/md'
import {BsWhatsapp} from 'react-icons/bs'
import Navbar from '../navbar/Navbar'
const Contact = () => {
    const links = [
        { text: "Home", href: "/" },
        { text: "Mail us", href: "#subscribe" }
      ];
      const button = { text: "Contact Us", btnClass: "btn-dark", href: "#faq" };

      const sendEmail = () => {
        const recipient = 'patriciaHealth@gmail.com';
        const subject = 'Message from Contact Form';
        const body = `
            Name: [Name Value]
            Email: [Email Value]
            Message: [Message Value]
        `;
        window.location.href = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    };
  return (
    <div id='container'>
      <Navbar links={links} button={button} />

    <section className='container contact'>
        <div>
            <p>
            "Have questions or feedback? We'd love to hear from you! Get in touch with our friendly team today for assistance, inquiries, or just to say hello. We're here to help!"





            </p>
        <h5>Get In Touch</h5>
    <h2> Contact Us</h2>
        </div>
   

    <div className='container contact_container'>
      <div className='contact_options'>
        <article className='contact_option'>
          <MdAttachEmail className='contact_option-icon'/>
          <h4>Email</h4>
          <h5> patriciaHealth@gmail.com</h5>
          <a href="mailto:info@patriciaheath.care" target='_blank'> Send a message</a>
        </article>
        <article className='contact_option'>
          <BsWhatsapp color='green' className='contact_option-icon'/>
          <h4>Whatsapp</h4>
          <h5> +267-361-9495</h5>
          <a href="https://api.whatsapp.com/send?phone=2673619495" target='_blank'> Send a message</a>
        </article>
       
      </div>

      <form action="">
        <input type="text" name='name' placeholder='Your Full Name' required/>


          <input type="email"name='email' placeholder='Your Email' required />
          <textarea name="message" rows="7" placeholder='Your Message' required></textarea>
          <button type='button' onClick={sendEmail} className='btn btn-primary'>Send Message</button>
      </form>
    </div>
  </section>
  </div>
  )
}

export default Contact