import React, { useState } from "react";
import { AiOutlineBars } from "react-icons/ai";
import { RiCloseLine } from "react-icons/ri";
import Button from "../UI/Button/Button";
import "../UI/Button/Button.css";
import LOGO from '../../assets/colored2.png'
import "./Navbar.css";
import { Link } from "react-router-dom";

const Navbar = ({ links, button }) => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <nav className="navbar ">
      <div className="logo">
        <Link to='/'> <img src={LOGO}/></Link>
      </div>
      <menu>
        <ul
          className="nav-links"
          id={showMenu ? "nav-links-mobile" : "nav-links-mobile-hide"}
        >
          {links.map((link, index) => (
            <li key={index}>
              <a href={link.href}>{link.text}</a>
              {/* <Link to={link.route}>{link.link}</Link> */}
            </li>
          ))}
          <li className="nav-btn">
            <Button text={button.text} btnClass={button.btnClass} href={button.href} />
          </li>
        </ul>
      </menu>
      <div className="menu-icons" onClick={toggleMenu}>
        {showMenu ? (
          <RiCloseLine color="#0024ff" size={30} />
        ) : (
          <AiOutlineBars color="#1f93ff" size={27} />
        )}
      </div>
    </nav>
  );
};

export default Navbar;
