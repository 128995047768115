import { FaCheckDouble, FaUserNurse } from "react-icons/fa";
import { IoIosContact,IoIosSettings } from "react-icons/io";
import CountUp from "react-countup";



export const awards = [
    {
      icon: <FaUserNurse  color='#1f93ff'/>,
      num: <CountUp end={5000} duration={5} />  ,
      name: "matched health workers",
    },
    {
      icon: <IoIosContact  color='#1f93ff'/>,
      num: "24-Hour Availability",
      name: " No matter what time of the day, week or year you need assistance, our client managers are always on-call to deal with any problem or last minute request for staffing solutions.",
    },
    {
      icon: <IoIosSettings  color='#1f93ff'/>,
      num: "Precise Profiling",
      name: "We make sure our staff are the perfect fit for your requirements, we profile all our clients in advance, so we can then match them up against our extensive staff database. We’ll sit down with you to discuss exactly what you need, from staff capabilities and qualifications through to shift patterns and services.",
    },
    {
      icon: <FaCheckDouble  color='#1f93ff'/>,
      num: "spot inspections",
      name: "We make sure our staff are continuously meeting our high standards, we cooperate with our clients to perform regular spot checks for quality and consistency.",
    },
  ]