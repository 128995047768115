import React from 'react'
// import Heading from "../../common/Heading"
import { awards } from "../awards/awardData"
import "./awards.css"

const Awards = () => {
  return (
    <>
    <section className='awards padding'>
      <div className='container'>
        {/* <Heading title='We"ve developed over 10,000+ Rated chess players ' subtitle='Our Awards' /> */}


<h1>Recruitment and Vetting</h1>
        <div className='content grid4 mtop'>
          {awards.map((val, index) => (
            <div className='box' key={index}>
              <div className='icon'>
                <span>{val.icon}</span>
              </div>
              <h1>{val.num}  </h1>
              <p>{val.name}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  </>
  )
}

export default Awards