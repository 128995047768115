import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
// import Navbar from "./components/navbar/Navbar";
// import Header from "./components/header/Header";
// import Features from "./components/features/Features";
// import Download from "./components/download/Download";
// import Subscribe from "./components/subscribe/Subscribe";
// import Footer from "./components/footer/Footer";

import {
  Navbar,
  Header,
  Features,
  Download,
  Subscribe,
  
  Footer,
} from "./components";
import Awards from "./components/awards/Awards";
import Contact from "./components/contact/Contact";
const links = [
  { text: "Home", href: "#",to:'', route:'' },
  { text: "About", href: "#features" },
  { text: "Subscribe", href: "#subscribe" }
];

const button = { text: "Contact Us", btnClass: "btn-dark", href: "/contact" };

function App() {
  return (
    <>
    <BrowserRouter>

    <Routes>
  
  <Route path="/" element={<> <header className="header-bg">
  <Navbar links={links} button={button}  />

        <Header />
        
      </header>
      <Features data-aos="fade-up" />
      <Download />
      <Awards/>
      <Subscribe />
      </>
     }/>
     <Route path="contact" element={<Contact/>}/>
     
     </Routes>
      <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
