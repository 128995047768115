import React, { useEffect } from "react";
import "./Subscribe.css";

import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";

import AOS from "aos";
import "aos/dist/aos.css";

const Subscribe = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <section id="subscribe">
      <div className="container subscribe" data-aos="fade-up">
        <h2>Subscribe now!</h2>
        <form>
          <div className="form-control">
            <input type="text" placeholder="Enter Your Email..." />
            <button><a className="buttonlink" href="mailto:info@patriciaheath.care">Subscribe</a></button>
          </div>
        </form>
        <div className="social-icons">
          
        <a><div className="social-icon">
          <FaFacebookF />
          </div></a> 
          <a><div className="social-icon">
          <FaTwitter />
          </div></a> 
          <a>  <div className="social-icon">
          <FaInstagram />
          </div></a> 
        </div>
      </div>
    </section>
  );
};

export default Subscribe;
