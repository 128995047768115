import React from "react";
import "./Footer.css";
import logo from "../../assets/white.png";
import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaFax,
  FaEnvelope,
  FaGlobe,
  FaTimes
} from "react-icons/fa";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <section id="footer">
      <div></div>
      <div className="container footer">
        <div className="footer-box">
          <h4>Useful Links</h4>
          <div className="footer-links">
          
            <a href="#"> ABOUT US</a>
          </div>
        </div>
        <div className="footer-box">
          <h4>Support Center</h4>
          <div className="footer-links">
            <a href="#"> CONTACT US</a>
            <a href="#"> TERMS AND CONDITIONS</a>
            <a href="#"> PRIVACY POLICY</a>
            <a href="#"> SUBSCRIBE</a>
            <a href="#"> ABOUT US</a>
          </div>
        </div>
        <div className="footer-box">
          <h4>Contact Us</h4>
          <div className="footer-contact u-text-small">
            
            <p>
              <FaPhoneAlt className="--my" />  +267-361-9495.
            </p>
            
            <p>
              <FaEnvelope className="--my" />  patriciaHealth@gmail.com
            </p>
            <p>
              <FaGlobe className="--my" />  www.patriciahealth.com
            </p>
          </div>
        </div>
        <div className="footer-box">
       <img src={logo}/>
       
         
        </div>
      </div>
      <p className="u-text-small footer-text">&copy; Copyright {currentYear}. Patricia Healthcare Services LLC</p>
    </section>
  );
};

export default Footer;
