import { FaAccessibleIcon, FaGg } from "react-icons/fa";
import { SiDatabricks } from "react-icons/si";
import { MdConnectWithoutContact } from "react-icons/md";

export const FeatureList = [
  {
    id: 1,
    icon: <FaAccessibleIcon color="#0a1930" size={10} />,
    heading: "Personal care assistance.",
    text: "Personal care assistance.",
  
  },
  {
    id: 2,
    icon: <SiDatabricks color="#0a1930" size={10} />,
    heading: "Medication management",
    text: "Medication management",
  },
  {
    id: 3,
    icon: <MdConnectWithoutContact color="#0a1930" size={10} />,
    heading: "Wound care.",
    text: "Wound care.",
  },
  {
    id: 4,
    icon: <FaGg color="#0a1930" size={10} />,
    heading: "Rehabilitation support.",
    text: "Rehabilitation support.",
  },
  {
    id: 5,
    icon: <FaGg color="#0a1930" size={10} />,
    heading: "Companionship and social support.",
    text: "Companionship and social support.",
  },
  {
    id: 5,
    icon: <FaGg color="#0a1930" size={10} />,
    heading: "Respite care.",
    text: "Respite care.",
  },
 

];
